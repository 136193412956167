<template>
  <div class="container">
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="box" v-for="item in list" :key="item.id" v-if="!isDetail">
      <div class="list" @click="toDetail(item)">
        <div class="item">
          <img :src="item.img" alt="">
          <div class="item__center">
            <div class="item__center__title">{{item.title}}</div>
            <div class="item__center__desc">{{item.introduction}}</div>
          </div>
          <div class="item__right">{{item.createTime | dateFormat}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="detail" v-if="isDetail">
      <div class="detail__title">
        <div class="detail__title__inner">
          <div class="back" @click="hideDetail">&lt; 返回慈善列表</div>
          <div class="text">{{detail.title}}</div>
        </div>
      </div>
      <div class="detail__content">
        <div class="ql-editor article" v-html="detail.content"></div>
      </div>
    </div> -->
    <SideBar />
  </div>
</template>

<script>
import { getArticleListAPI, getArticleDetailAPI } from '@/api'
import SideBar from '@/components/SideBar.vue'
export default {
  components: {
    SideBar,
  },
  data() {
    return {
      isDetail: false,
      detail: {},
      pageNum: 1,
      pageSize: 100,
      first: {},
      list: [],
      scrollTop: '',
      listScrollTop: '',
    }
  },
  filters: {
    dateFormat(val) {
      let arr = val.split(' ')
      return arr[0]
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    this.getList()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true) // 监听（绑定）滚轮滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll) //离开页面需要移除这个监听的事件
  },
  methods: {
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
    },
    toDetail(item) {
      console.log('跳转到慈善详情')
      const path = `/charity/detail`
      sessionStorage.setItem('charityDetail', JSON.stringify(item))
      this.$router.push(path)
      // this.isDetail = true
      // this.detail = item
      // this.listScrollTop = this.scrollTop
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // })
    },
    hideDetail() {
      console.log('this.scrollTop', this.scrollTop)
      this.isDetail = false
      window.scrollTo({
        top: this.listScrollTop,
        behavior: 'smooth',
      })
    },
    async getList() {
      // const { id } = this.activeItem
      const id = 26
      const { list } = await getArticleListAPI({
        id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
      if (this.pageNum > 1) {
        this.list = [...this.list, ...list]
      } else {
        // const first = list.shift()
        // this.first = first
        this.list = list
      }
    },
    // loadMore() {
    //   this.pageNum++
    //   this.getList()
    // },
    // setIsDetail(isDetail) {
    //   this.isDetail = isDetail
    // },

    // async quickChange(id, menuId) {
    //   const data = await getArticleDetailAPI({
    //     id,
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum,
    //     menuId,
    //   })
    //   this.detail = data
    // },
  },
  computed: {
    activeItem: function () {
      return this.$store.state.activeItem
    },
  },
  watch: {
    activeItem() {
      // this.setIsDetail(false)
      this.getList()
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background: #fff;
  margin-top: 0.92rem;
  overflow: hidden;
}
.banner {
  width: 100%;
}
.list {
  width: 18rem;
  margin: 0.32rem auto 0;
  padding: 0.53rem 0 0.3rem;
  transition: margin-left 1s linear 0;
  transition: width 1s linear 0;
  .item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    img {
      width: 4.15rem;
      height: 2.48rem;
      // margin-right: 0.64rem;
    }
    &__center {
      width: 11rem;
      &__title {
        font-size: 0.24rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 0.28rem;
      }
      &__desc {
        font-size: 0.19rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.28rem;
        margin-top: 0.51rem;
      }
    }
    &__right {
      font-size: 0.24rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.47rem;
      letter-spacing: 1px;
    }
  }
}
.box:hover {
  background: #f9fbfc;
  .list {
    padding-left: 0.3rem;
    box-sizing: border-box;
    width: 18.3rem;
    // margin-left: 4.2rem;
  }
}
// .box:nth-child(2n + 1) {
//   background: #f9fbfc;
// }
.detail {
  &__title {
    width: 100%;
    height: 1.57rem;
    background: #f9fbfc;
    &__inner {
      width: 18rem;
      margin: 0 auto;
      .back {
        font-size: 0.19rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 0.22rem;
        padding: 0.24rem 0 0.31rem 0;
        cursor: pointer;
      }
      .text {
        font-size: 0.24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.28rem;
      }
    }
  }
  &__content {
    padding: 0.57rem 0;
    .article {
      width: 18rem;
      margin: 0 auto;
    }
  }
}
</style>